import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import * as StatisticsApis from '../apis/statistics.api';
import { ActionType, DeviceType, StatisticsContextType } from '../types/statistics.interface';


const StatisticsContext = createContext<StatisticsContextType | undefined>(undefined);

export const useStatisticsContext = () => {
    const context = useContext(StatisticsContext);
    if (!context) {
        throw new Error('Error');
    }
    return context;
};

export const StatisticsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [deviceType, setDeviceType] = useState<DeviceType>('desktop');
    const [actionType, setActionType] = useState<ActionType>("visit");

    useEffect(() => {
        if (isMobile) {
            setDeviceType('mobile');
        } else if (isTablet) {
            setDeviceType('tablet');
        }

        const sendDeviceType = () => {
            StatisticsApis.saveUserStatistics({ action: actionType, device: deviceType }).then((res) => {
                console.log("🚀 ~ res:", res);
            }).catch((err) => {
                console.log("🚀 ~ err:", err);
            })
        };

        sendDeviceType();
    }, [actionType, deviceType]);

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY;
            const scrollHeight = document.documentElement.scrollHeight;
            const threshold = scrollHeight * 0.75;

            if (scrolled > threshold) {
                setActionType('scrolled-past-75');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <StatisticsContext.Provider value={{ deviceType, actionType, setActionType }}>
            {children}
        </StatisticsContext.Provider>
    );
};
