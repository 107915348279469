// AlertProvider.tsx
import React, { useState, createContext, useContext } from "react";
import { SuccessAlert } from "../Components/Alert/Success";
import { ErrorAlert } from "../Components/Alert/Error";

type AlertType = "success" | "error";

interface AlertContextType {
    showAlert: (message: string, type: AlertType) => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [alert, setAlert] = useState<{ message: string; type: AlertType } | null>(null);

    const showAlert = (message: string, type: AlertType) => {
        setAlert({ message, type });
        setTimeout(() => setAlert(null), 2000);
    };

    return (
        <AlertContext.Provider value={{ showAlert }}>
            {children}
            {alert && (
                <>
                    {alert.type === "success" && <SuccessAlert message={alert.message} />}
                    {alert.type === "error" && <ErrorAlert message={alert.message} />}
                </>
            )}
        </AlertContext.Provider>
    );
};

export const useAlert = (): AlertContextType => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error("useAlert must be used within an AlertProvider");
    }
    return context;
};
