import { HeaderContainer } from "./style"
import { ReactComponent as Logo } from "../../assets/images/logos/Logo-svg.svg"
import { ReactComponent as EarthIcon } from '../../assets/icons/earth.svg';
import { ReactComponent as BurgerMenu } from '../../assets/icons/burger-list-menu.svg';
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link"
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface Props {
    setOpenSidebar: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ setOpenSidebar }: Props) => {
    const [isScrolled, setIsScrolled] = useState<boolean>(false);

    const { t, i18n } = useTranslation()

    const changeLanguageHandler = (lang: string) => {
        i18n.changeLanguage(lang).then(() => {
            window.location.reload(); // This reloads the page
        });
        localStorage.setItem('language', lang);
    }

    // Function to handle scroll event
    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    // Adding the scroll event listener
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <HeaderContainer>
            {/* mobile header */}
            <div className={`mobile-header ${isScrolled ? "add-bg" : "remove-bg"}`}>
                {/* Right side */}
                <Logo className="logo" />

                {/* left side */}
                <div className="header-left-side">
                    {i18n.language === "ar" ?
                        <div onClick={() => changeLanguageHandler("en")}>
                            <span className="earth-bg">
                                <EarthIcon className="earth-icon" />
                            </span>
                        </div>
                        :
                        <div onClick={() => changeLanguageHandler("ar")}>
                            <span className="earth-bg">
                                <EarthIcon className="earth-icon" />
                            </span>
                        </div>
                    }
                    <div className="earth-bg" onClick={() => setOpenSidebar(true)}>
                        <BurgerMenu className="earth-icon" />
                    </div>
                </div>
            </div>

            {/* desktop header */}
            <div className={`header ${isScrolled ? "add-bg" : "remove-bg"}`}>
                {/* Right side */}
                <Logo className="logo" />

                {/* center */}
                <ul>
                    <li><Link to="#home" className="link">{t("home")}</Link></li>
                    <li><Link to="#about" className="link">{t("about")}</Link></li>
                    <li><Link to="#quote-request" className="link">{t("offerPrice")}</Link></li>
                    <li><Link to="#contact" className="link">{t("contact")}</Link></li>
                </ul>

                {/* left side */}
                {i18n.language === "ar" ?
                    <h3 onClick={() => changeLanguageHandler("en")}>
                        <span className="earth-bg">
                            <EarthIcon className="earth-icon" />
                        </span>
                        <span className="text">
                            English
                        </span>
                    </h3>
                    :
                    <h3 onClick={() => changeLanguageHandler("ar")}>
                        <span className="earth-bg">
                            <EarthIcon className="earth-icon" />
                        </span>
                        <span className="text">
                            عربي
                        </span>
                    </h3>
                }
            </div>
        </HeaderContainer>
    )
}

export default Header