import styled from "styled-components";
import Delivery from "../../assets/images/delivery-img.jpg"


export const QuateRequestContainer = styled.section`
    margin-top: 60px;
    margin-bottom: 400px;
    
    .request-container{
        position: relative;
        background: url(${Delivery});
        background-attachment: fixed;
        background-color: #cccccc;
        height: 500px;
        background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
    }


    .quote-container{
        position: absolute;
        background-color: #FFFFFF;
        padding: 3rem 5.5rem;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 24px;
        width: 80%;
    }

    .img-container{
        position: relative;
    }

    .quote-img{
        max-width: 100%;
        object-fit: cover;
        border-radius: 1rem;
    }

    .backages-img{
        max-width: 100%;
    }

    .quote-title{
        color: #A2C138;
        font-family: Cairo;
        font-style: normal;
        font-weight: 700;
        font-size: 2.5rem;           
        line-height: 3.325rem;      
        margin-bottom: 0.75rem;     

    }

    .quote-description{
        color: #0F3D57;
        font-family: Cairo;
        font-style: normal;
        font-weight: 500;
        font-size: 1.25rem;          
        line-height: 1.6rem;      
        margin-bottom: 1.5rem;        

    }

    .backages-img-container:dir(rtl){
        position: absolute;
        background-color: #FFFFFF;
        bottom: -1.875rem;       
        left: -3.75rem;         
        padding: 0.9375rem 0.9375rem 0 0; 
        border-radius: 1rem 1rem 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .backages-img-container:dir(ltr){
        position: absolute;
        background-color: #FFFFFF;
        bottom: 0rem;       
        right: -2rem;          
        padding: 0.9375rem 0 0 0.9375rem; 
        border-radius: 1rem 1rem 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .quote{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
    }

    .quote-form{
        width: 70%;
    }

    .form-row{
        width: 100%;
        display: flex;
        gap: 1rem;
        margin-bottom: 1.5rem;
    }

    .field-container{
        width: 100%;
    }

    .form-field {
        width: 100%;
        padding: 9px 3px 9px 3px;
        border-radius: .5rem;
        border: 1px solid  #F0EEEF;
        background: var(--gray-1, #F9FAFB);
        color: var(--text-sub, #374151);
        font-family: Cairo;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        outline: none;
    }

    .quote-form .contact-btn{
        margin: auto;
        padding: 1.5rem 0;
    }

    @media (max-width: 1024px){
        .quote-container{
            padding: 3rem 4rem;
        }

        .backages-img{
            max-width: 12rem;
        }

        .backages-img-container:dir(rtl){
            bottom: -1rem;        
            left: -1rem; 
        }

        .backages-img-container:dir(ltr){
            bottom: -1rem;        
            right: -1rem; 
        }
    }

    @media (max-width: 768px){
        margin-bottom: 460px;
        
        .quote-container{
            background-color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2rem;
            padding: 3rem 2rem;
        }

        .backages-img-container:dir(rtl){
            bottom: -1rem;        
            left: -1rem; 
        }

        .backages-img-container:dir(ltr){
            bottom: -1rem;        
            right: -1rem; 
        }

        .backages-img{
            max-width: 10rem;
        }

        .quote-img{
            max-width: 15rem;
        }

        .quote-title{
            font-size: 1.8rem;           
            line-height: 3rem;
        }


        .quote-description{
            font-size: 1rem;
            line-height: 1rem;
        }

        .form-row{
            display: flex; 
            flex-direction: column;
            margin-bottom: 1rem;
        }

        .form-field{
            padding: 4px 4px 4px 0;
            font-size: .9rem;
        }

        .select{
            font-size: .9rem;
        }

        .quote-form{
            width: 100%;
        }

        .quote-form .contact-btn{
            font-size: .9rem;
            padding: 1.2rem 0;
            margin: auto;
        }
    }

    @media (max-width: 480px){
        margin-bottom: 400px;

        .request-container {
            background-attachment: scroll; 
        }

        .quote-container{
            background-color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 2rem;
            padding: 3rem 1.5rem;
        }

        .img-container{
            display: none;
        }

        .quote-description{
            font-size: 1rem;
            line-height: 1rem;
        }

        .form-row{
            display: flex; 
            flex-direction: column;
            margin-bottom: 1rem;
        }

        .form-field{
            padding: 4px 4px 4px 0;
            font-size: .9rem;
        }

        .quote-form{
            width: 100%;
        }

        .quote-form .contact-btn{
            width: 100%;
            font-size: .9rem;
            padding: 1.2rem 0;
        }
    }
`;