import { QuateRequestContainer } from './style'
import QuateImg from "../../assets/images/quote-img.png"
import Backages from "../../assets/images/backages.png"
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { saveQuotation } from '../../apis/quotations.api';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from "yup";
import { useAlert } from '../../context/AlertContext';


const QuoteRequest = () => {
    const { t } = useTranslation();
    const { showAlert } = useAlert();

    // validation schema
    const schema = yup.object({
        name: yup.string().required(t("nameRequired")),
        email: yup.string().email(t("invalidEmail")).required(t("emailRequired")),
        phone: yup.string().required(t("phoneRequired")),
        serviceType: yup.string().required(t("serviceTypeRequired")),
        requestDescription: yup
            .string()
            .required(t("requestDescriptionRequired"))
            .min(10, t("requestDescriptionMinLength")),
    }).required();

    type FormData = yup.InferType<typeof schema>;

    const { register, handleSubmit, reset, formState: { errors } } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            email: "",
            phone: "",
            serviceType: "vip-service",
            requestDescription: "",
        }
    });

    const onSubmit: SubmitHandler<FormData> = data => {
        saveQuotation(data).then(() => {
            showAlert(t("quotationRequestSentSuccessfully"), "success");
            reset();
        }).catch(() => {
            showAlert(t("somethingWentWrong"), "error");
        });
    }

    return (
        <QuateRequestContainer id="quote-request">
            <div className='request-container'>
                <div className='quote-container'>
                    <div className="quote">
                        <form onSubmit={handleSubmit(onSubmit)} className='quote-form'>
                            <div className='quote-title'>{t("quoteRequest")}</div>
                            <div className='quote-description'>{t("fillquoteRequestForm")}</div>
                            <div className="form-row">
                                <div className="field-container">
                                    <input placeholder={t("name")} {...register("name")} className='form-field' />
                                    <div className='field-error'>{errors.name?.message}</div>
                                </div>
                                <div className="field-container">
                                    <input placeholder={t("email")} {...register("email")} className='form-field' />
                                    <div className='field-error'>{errors.email?.message}</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="field-container">
                                    <input placeholder={t("phone")} {...register("phone")} className='form-field' />
                                    <div className='field-error'>{errors.phone?.message}</div>
                                </div>
                                <div className="field-container">
                                    <select {...register("serviceType")} className='form-field'>
                                        <option value="vip-service">{t("vipService")}</option>
                                        <option value="regular-service">{t("regularService")}</option>
                                    </select>
                                    <div className='field-error'>{errors.serviceType?.message}</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="field-container">
                                    <textarea rows={4} placeholder={t("requestDescription")} {...register("requestDescription")} className='form-field full-width-field' />
                                    <div className='field-error'>{errors.requestDescription?.message}</div>
                                </div>
                            </div>
                            <button type='submit' className="contact-btn">{t("contact")}</button>
                        </form>
                        <div className='img-container'>
                            <img src={QuateImg} alt='quote' className='quote-img' />
                            <div className='backages-img-container'>
                                <img src={Backages} alt='backages' className='backages-img' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </QuateRequestContainer>
    )
}

export default QuoteRequest