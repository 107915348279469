import styled from "styled-components";

export const ServicesContainer = styled.section`
    background: linear-gradient(to right, rgba(15, 61, 87, .9) 100%, rgba(15, 61, 87, 0.7) 0%);
    padding: 0;
    margin-bottom: 60px;

    .server-container{
        padding: 4rem;
        display: flex;
        justify-content: space-between;
        align-items: end;
    }

    .service-text{
        max-width: 444px;
        position: relative;
        margin: 4rem;
    }

    .service-title{
        color: #FFFFFF;
        font-family: Cairo;
        font-size: 3rem;
        font-style: normal;
        font-weight: 400;
        span{
            color: #7B932B;
        }
    }

    .service-title:dir(rtl){
        padding-left: 150px;
    }

    .service-description{
        color: #FFF;
        font-family: Cairo;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        padding-bottom: 22px;
    }

    .svg-shape{
        position: absolute;
        top: -20px;
        right: -90px;
    }

    .svg-shape:dir(ltr){
        transform: rotateY(180deg);
        top: -20px;
        left: -90px;
    }

    .map-img{
        max-width: 100%;
    }

    .markers-img{
        max-width: 100%;
    }

    .service-map{
        position: relative;
        width: 50%;
    }

    .markers-img{
        position: absolute;
        top: 1rem;
        left: 50%;
        transform: translateX(-50%);
    }

    .markers-img:dir(ltr){
        position: absolute;
        top: 1;
        left: 60%;
        transform: translateX(-50%);
    }


    @media (max-width: 1024px){
        .service-title{
            font-size: 2rem;
        }

        .service-text .contact-btn{
            font-size: 1rem;
            width: 15rem;
            padding: .5rem 0;
        }

        .service-title:dir(rtl){
            padding-left: 100px;
        }

        .map-img{
            max-width: 27rem;
        }

        .markers-img{
            max-width: 18rem;
        }
    }

    @media (max-width: 768px){
        .server-container{
            padding: 0 4rem;
        }

        .service-title{
            font-size: 2rem;
        }

        .service-text{
            margin: 2rem;
        }

        .service-title:dir(rtl){
            padding-left: 0;
        }

        .service-text .contact-btn{
            font-size: 1rem;
            width: 15rem;
            padding: 1.3rem 0;
        }

        .map-img{
            max-width: 22rem;
        }

        .markers-img{
            max-width: 18rem;
        }

        .svg-shape{
            position: absolute;
            top: -3rem;
            right: -4.5rem;
        }

        .svg-shape:dir(ltr){
            top: -3rem;
            left: -4.5rem;
        }
    }

    @media (max-width: 480px){
        .server-container{
            flex-direction: column;
            justify-content: center;
            padding: 4rem;
        }

        .service-title{
            font-size: 1.5rem;
        }

        .service-text{
            width: 100%;
            max-width: 100%;
            margin: 0rem;
        }

        .service-text .contact-btn{
            font-size: 1rem;
            width: 100%;
            padding: .8rem 0;
        }

        .service-title:dir(rtl){
            padding-left: 0;
        }

        .service-map{
            width: 100%;
        }

        .map-img{
            max-width: 20rem;
        }

        .markers-img{
            max-width: 15rem;
        }

        .svg-shape{
            position: absolute;
            top: -3rem;
            right: -4.5rem;
        }
        
        .svg-shape:dir(ltr){
            top: -3rem;
            left: -4.5rem;
        }
    }
`;