import { PartnerContainer } from './style'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Line } from "../../assets/images/partner-line.svg"
import { ReactComponent as HungerStation } from "../../assets/images/logos/hunger-station.svg"
import { ReactComponent as TheChefz } from "../../assets/images/logos/the-chefz.svg"
import { ReactComponent as Noon } from "../../assets/images/logos/noon.svg"
import { ReactComponent as Imile } from "../../assets/images/logos/imile.svg"
import { ReactComponent as NoonMinutes } from "../../assets/images/logos/noon-minutes.svg"
import { ReactComponent as NaqelExpress } from "../../assets/images/logos/naqel-express.svg"
import { ReactComponent as Ninja } from "../../assets/images/logos/ninja.svg"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { isMobile } from 'react-device-detect';

// Import Swiper styles
import 'swiper/css';

import { Autoplay } from 'swiper/modules';


const Partners = () => {
    const { t, i18n } = useTranslation()
    return (
        <PartnerContainer>
            <div className='partner-text'>
                <div className='partner-title'>{t("partners")}</div>
                <Line />
            </div>
            <Swiper
                slidesPerView={isMobile ? 5 : 7}
                spaceBetween={11}
                loop={true}
                // loopAdditionalSlides={7}
                watchSlidesProgress={true}
                autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                }}
                speed={2000}
                modules={[Autoplay]}
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                className="my-swiper"
            >
                {isMobile ?
                    <>
                        <SwiperSlide className='card' style={{ backgroundColor: "#f8ee4f" }}>
                            <HungerStation className="card-small-icon" />
                        </SwiperSlide>
                        <SwiperSlide className='card white-card'>
                            <TheChefz className="card-icon chefz-icon" />
                        </SwiperSlide>
                        <SwiperSlide className='card' style={{ backgroundColor: "#f8e852" }}>
                            <Noon className="card-small-icon" />
                        </SwiperSlide>
                        <SwiperSlide className='card white-card'>
                            <Imile className="card-icon" />
                        </SwiperSlide>
                        <SwiperSlide className='card' style={{ backgroundColor: "#ffee2e" }} >
                            <NoonMinutes className="card-icon" />
                        </SwiperSlide>
                        <SwiperSlide className='card white-card'>
                            <NaqelExpress className="card-icon" />
                        </SwiperSlide>
                        <SwiperSlide className='card white-card'>
                            <Ninja className="card-icon" />
                        </SwiperSlide>
                    </>
                    :
                    Array(2).fill(null).flatMap(() => (
                        <>
                            <SwiperSlide className='card' style={{ backgroundColor: "#f8ee4f" }}>
                                <HungerStation className="card-small-icon" />
                            </SwiperSlide>
                            <SwiperSlide className='card white-card'>
                                <TheChefz className="card-icon chefz-icon" />
                            </SwiperSlide>
                            <SwiperSlide className='card' style={{ backgroundColor: "#f8e852" }}>
                                <Noon className="card-small-icon" />
                            </SwiperSlide>
                            <SwiperSlide className='card white-card'>
                                <Imile className="card-icon" />
                            </SwiperSlide>
                            <SwiperSlide className='card' style={{ backgroundColor: "#ffee2e" }} >
                                <NoonMinutes className="card-icon" />
                            </SwiperSlide>
                            <SwiperSlide className='card white-card'>
                                <NaqelExpress className="card-icon" />
                            </SwiperSlide>
                            <SwiperSlide className='card white-card'>
                                <Ninja className="card-icon" />
                            </SwiperSlide>
                        </>
                    )
                    )
                }

            </Swiper>
            <div className='bottom-div'></div>
        </PartnerContainer>
    )
}

export default Partners