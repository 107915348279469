import styled from "styled-components";

export const SidebarContainer = styled.div`
    .close-icon{
        position: absolute;
        top: 2rem;
        right: 2rem;
        height: 1.9rem;
        width: 1.9rem;
        font-weight: 900;
        z-index: 9999;
    }

    .sidebar-container{
        position: fixed;
        z-index: 999;
        height: 100%;
        width: 100%;
        margin: auto;
        background-color: rgba(15, 61, 87, 0.6);
        list-style: none;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 1.3rem;
    }

    .link{
        color: #FFFFFF;
    }
`