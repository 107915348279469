import styled from "styled-components";

export const AlertContainer = styled.div`
    position: fixed;
    z-index: 9999;
    bottom: 50px;
    left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;`