import Alert from "@mui/material/Alert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { AlertContainer } from "./style";



export const ErrorAlert: React.FC<{ message: string }> = ({ message }) => {
  return (
    <AlertContainer>
      <Alert icon={<ErrorOutlineIcon fontSize="inherit" />} severity="error" className="alert">
        {message}
      </Alert>
    </AlertContainer>
  );
}
