import styled from "styled-components";

export const CardContainer = styled.div`
    .card-container{
        position: relative;
        width: 40rem;
        height: 21rem;
        margin-bottom: 2rem;
    }

    .first-card{
        position: absolute;
        width: 28rem;
        height: 14rem;
        padding: 1.5rem;
        top: 0; 
        left: 50%;
        transform: translate(-50%);
        background-color: #FFFFFF;
        box-shadow: 0px 18px 52.85px 0px #D7E4F980;
        border-radius: 16px;
        z-index: 3;
    }

    .second-card{
        position: absolute;
        background-color: #FFFFFF;
        box-shadow: 0px 18px 52.85px 0px #D7E4F980;
        width: 29rem;
        height: 16rem;
        top: 1.8rem; 
        left: 50%;
        transform: translate(-50%); 
        border-radius: 16px;
        z-index: 2;
    }

    .third-card{
        position: absolute;
        background-color: #FFFFFF;
        box-shadow: 0px 18px 52.85px 0px #D7E4F980;
        width: 26rem;
        height: 16rem;
        top: 2.5rem; 
        left: 50%;
        transform: translate(-50%); 
        border-radius: 16px;
        z-index: 1;
    }

    .card-content{
        color: #0F3D57;
    }

    .card-title:dir(ltr){
        margin-top: 0;
    }

    .card-text{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;
    }

    .card-title:dir(rtl){
        gap: 1rem;
        opacity: 0px;
        font-family: Cairo;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 3.7rem;
    }

    .card-title:dir(ltr){
        gap: 1rem;
        opacity: 0px;
        font-family: Cairo;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 3.7rem;
    }

    p {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
        text-align: justify;
    }

    @media (max-width: 1024px){
        .card-container{
            position: relative;
            width: 26rem;
            min-height: 21rem;
            margin-bottom: 60px;
        }

        .first-card{
            max-width: 24rem;
            min-height: 16rem;
        }

        .second-card{
            max-width: 26rem;
            min-height: 18rem;
        }

        .third-card{
            max-width: 25rem;
            min-height: 18rem;
        }
    }

    @media (max-width: 768px){
        .card-container{
            position: relative;
            max-width: 100%;
            min-height: 21rem;
            margin-bottom: 60px;
            margin: auto;
        }

        .first-card{
            max-width: 36rem;
            min-height: 16rem;
        }

        .second-card{
            max-width: 37rem;
            min-height: 18rem;
        }

        .third-card{
            max-width: 36rem;
            min-height: 18rem;
        }
    }

    @media (max-width: 480px){
        .card-container{
            position: relative;
            width: 100%;
            height: 21rem;
            margin-bottom: 60px;
            margin: auto;
        }

        .first-card{
            width: 17rem;
            height: 16rem;
        }

        .second-card{
            width: 18rem;
            height: 18rem;
        }

        .third-card{
            width: 16rem;
            height: 18rem;
        }

        .card-title:dir(ltr){
            font-size: .9rem;
        }
        
        .card-title:dir(rtl){
            font-size: .9rem;
        }

        p {
            font-size: .8rem;
        }
    }
`
