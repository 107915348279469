import { AboutContainer } from './style'
import Circle from "../../assets/images/circle.png"
import GrayBag from "../../assets/images/gray-bag.png"
import GreenBag from "../../assets/images/green-bag.png"
import Car from "../../assets/images/car.png"
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import Card from '../common/Card/Card'
import { ReactComponent as ServiceIcon } from '../../assets/icons/service.svg';
import { ReactComponent as GoalIcon } from '../../assets/icons/goals.svg';
import { ReactComponent as VisionIcon } from '../../assets/icons/vision.svg';
import { ReactComponent as MessageIcon } from '../../assets/icons/message.svg';
// import ScrollAnimation from "react-animate-on-scroll";
import { isMobile } from 'react-device-detect';


const About = () => {
    const { t, i18n } = useTranslation()
    return (
        <AboutContainer id="about">
            <div className='about-container'>
                <div className='about-top'>
                    <div className='about-imgs'>
                        <img src={Circle} alt='circle' className='circle-img' />
                        <div className='bag-wrapper'>
                            <img src={GreenBag} alt='greenBag' className='green-bag' />
                            <img src={GrayBag} alt='grayBag' className='gray-bag' />
                        </div>
                        <div className='car-container'>
                            <motion.img
                                src={Car}
                                alt='car'
                                animate={{ opacity: 0 }}
                                whileInView={{ opacity: 1, x: i18n.language === "ar" ? (isMobile ? [65, -65] : [100, -100]) : (isMobile ? [65, -65] : [100, -100]) }}
                                transition={{ type: "tween", duration: .7 }}
                                drag="x"
                                dragConstraints={{ left: 200 }}
                                className='car-img'
                            />
                        </div>
                    </div>
                    <div className='about-text'>
                        <div className='about-title'>{t("about")}</div>
                        <div className='about-description'>
                            {t("servicesDescription")}
                        </div>
                    </div>
                </div>
                <div className='cards-container'>
                    <div className='cards'>
                        <Card title={t("fastDelivery")} icon={<ServiceIcon className="service-icon" />} description={t("fastDeliveryDescription")} />
                        <Card title={t("eCommerce")} icon={<GoalIcon className="service-icon" />} description={t("eCommerceDescription")} />
                        {/* <ScrollAnimation animateIn="flipInX">
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="flipInX">
                        </ScrollAnimation> */}
                    </div>
                    <div className='cards'>
                        <Card title={t("insurance")} icon={<MessageIcon className="service-icon" />} description={t("insuranceDescription")} />
                        <Card title={t("successStories")} icon={<VisionIcon className="service-icon" />} description={t("successStoryDescription")} />
                        {/* <ScrollAnimation animateIn="flipInX">
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="flipInX">
                        </ScrollAnimation> */}
                    </div>
                </div>
            </div>
        </AboutContainer>
    )
}

export default About