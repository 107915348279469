import { useTranslation } from 'react-i18next'
import { ServicesContainer } from './style'
import { ReactComponent as ShapeImg } from "../../assets/images/shape.svg"
import { ReactComponent as MapImg } from "../../assets/images/world-map.svg"
import { ReactComponent as MapMarkersImg } from "../../assets/images/map-markers.svg"
import { HashLink as Link } from "react-router-hash-link"

const Services = () => {
    const { t } = useTranslation()
    return (
        <ServicesContainer id="services">
            <div className='server-container'>
                <div className='service-text'>
                    <ShapeImg className='svg-shape' />
                    <div className='service-title'>
                        {t("ourService")} <span>{t("reaches")}</span> {t("everyWhere")}
                    </div>
                    <div className='service-description' ></div>
                    <Link to="#contact" className="contact-btn link">{t("findNearBranch")}</Link>
                </div>
                <div className='service-map'>
                    <MapImg className="map-img" />
                    <MapMarkersImg className='markers-img' />
                </div>
            </div>
        </ServicesContainer >
    )
}

export default Services