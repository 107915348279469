import styled from "styled-components";

export const Container = styled.section`
    .video-container{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100vh;
    }

    video:dir(rtl){
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    video:dir(ltr){
        transform: rotateY(180deg);
        object-fit: cover;
        max-width: 100%;
        height: 100%;
        width: 100%;
    }

    .overlay:dir(ltr){
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        background: linear-gradient(to left, rgba(15, 61, 87, 0) 0%, rgba(15, 61, 87, 0.8) 100%);
        z-index: 1;
    }

    .overlay:dir(rtl){
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(15, 61, 87, 0) 0%, rgba(15, 61, 87, 0.8) 100%);
    }

    .green-vector:dir(rtl){
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 100%;
        height: auto;
    }

    .green-vector:dir(ltr){
        position: absolute;
        bottom: 0;
        right: 0;
        transform: rotateY(180deg);
        z-index: 3;
        max-width: 100%;
        height: auto;
    }

    .blue-vector:dir(rtl){
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 100%;
        height: auto;
    }

    .blue-vector:dir(ltr){
        position: absolute;
        bottom: 0;
        right: 0;
        transform: rotateY(180deg);
        z-index: 4;
        max-width: 100%;
        height: auto;
    }

    .hero-text-container:dir(rtl){
        position: absolute;
        top: 25%;
        right: 0;
        z-index: 10;
        max-width: 100%;
        text-align: justify;
    }

    .hero-text-container:dir(ltr){
        position: absolute;
        top: 25%;
        left: 0;
        z-index: 10;
        max-width: 100%;
        text-align: justify;
    }

    .hero-text {
        padding: 0 6.2rem;
    }

    .hero-text .h1 {
        max-width: 33.6rem;
        height: auto;
        color: #A2C138;
        text-shadow: -2px 5px 8px rgba(0, 0, 0, 0.20);
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 4rem;
        margin-bottom: 1rem;
    }

    .hero-text p {
        max-width: 41rem;
        color: #FFF;
        font-family: Cairo, sans-serif;
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 2rem;
    }

    .hero-description{
        color: #FFF;
        font-size: 1.1rem;
        max-width: 41rem;
    }

    .hero-description .sub-title{
        font-weight: 700;
    }

    .hero-description ul{
        padding: 0 2.5rem;
    }

    .contact-btn {
        margin-top: 2.06rem;
    }

    .whatsapp-img{
        position: fixed;
        bottom: 0;
        right: 0;
        width: 6rem;
        height: 6rem;
        flex-shrink: 0;
        cursor: pointer;
        z-index: 999;
    }

    @media (max-width: 1024px) {
        .video-container{
            height: auto;
        }

        .hero-text {
            padding: 0 5rem;
        }

        .hero-text .h1 {
            font-size: 2.2rem;
            line-height: 3.5rem;
            margin-bottom: 1.5rem;
        }

        .hero-text p {
            font-size: 1rem;
            line-height: 1.8rem;
        }

        .hero-description{
            font-size: 1rem;
        }

        .contact-btn {
            margin-top: 2rem;
        }

        .whatsapp-img{
            width: 6rem;
            height: 6rem;
        }

    }

    @media (max-width: 768px) {
        .video-container{
            height: 600px;
        }
        
        .hero-text {
            padding: 0 3rem;
        }

        .hero-text .h1:dir(ltr){
            max-width: 30rem;
        }

        .hero-text .h1 {
            max-width: 20rem;
            font-size: 2rem;
            line-height: 3rem;
            margin-bottom: 1rem;
        }

        .hero-text p {
            max-width: 30rem;
            font-size: .9rem;
            line-height: 1.6rem;
        }

        .hero-description{
            font-size: .9rem;
            max-width: 30rem;
        }

        .contact-btn {
            margin-top: 1rem;
            padding: .4rem .8rem; 
        }

        .whatsapp-img{
            width: 6rem;
            height: 6rem;
        }
    }

    @media (max-width: 480px) {
        .video-container{
            height: 500px;
        }

        .hero-text {
            padding: 0 1rem;
        }

        .hero-text .h1 {
            max-width: 13rem; 
            font-size: 1.3rem;
            line-height: 1.9rem;
        }

        .hero-text p {
            max-width: 18rem;
            font-weight: 400;
            font-size: .8rem;
            line-height: 1.2rem;
        }

        .hero-description{
            font-size: .8rem;
            max-width: 18rem;
        }

        .whatsapp-img{
            width: 5rem;
            height: 5rem;
        }

        .hero-text .contact-btn{
            padding: .6rem .6rem;
        }
    }

`;