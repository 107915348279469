import styled from "styled-components";

export const JoinContainer = styled.section`
    margin-top: 60px;
    
    .mobile{
        display: none;
    }

    .join-text{
        text-align: center;
        margin-bottom: 40px;

        .contact-btn{
            margin: 2rem auto 4rem auto;
            /* padding: 1.5rem */
        }
    }

    .join-title{
        color: #0F3D57;
        text-align: center;
        font-family: Cairo;
        font-size: 2.3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 66.5px; /* 138.542% */
    }

    .join-sub-title{
        color: #7B932B;
        font-family: Cairo;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 42.56px; /* 177.333% */
    }

    .join-description{
        align-self: stretch;
        color: var(--text-sub, #374151);
        text-align: center;
        font-family: Cairo;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 21.3px; /* 133.125% */
        max-width: 500px;
        margin: .5rem auto;
    }

    .join-imgs{
        position: relative;
    }

    .rectangle-img{
        width: 100%;
        object-fit: cover;
        position: absolute;
        bottom: 0;
    }

    .white-rectangle-container {
        display: flex;
        justify-content: center;
        align-items: end;
        margin-bottom: 60px;
        z-index: 0;
    }

    .white-rectangle-img{
        width: 100%;
        object-fit: cover;
    }


    .middle-rectangle{
        height: 300px;
    }

    .rectangle-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .centered-text {
        position: absolute;
        text-align: center;
        z-index: 1;
    }

    .text-title{
        color: var(--Black, #01120A);
        text-align: center;
        font-family: Cairo;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
    }

    .middle-title {
        font-size: 1.5rem;
        margin-top: 2rem;
    }

    .middle-description{
        width: 380px;
    }

    .text-description{
        color: var(--text-sub, #374151);
        text-align: center;
        font-family: Cairo;
        font-size: .9rem;
        font-style: normal;
        font-weight: 400;
        opacity: 0.6;
    }

    .centered-icon{
        position: absolute;
        top: -10px;
    }

    .storage-icon-container{
        background-color: #165B83;
        padding: 1.5rem 2rem;
        border-radius: 16px;
    }

    .plans-icon-container{
        background-color: #1E79AE;
        padding: .8rem 1.3rem;
        border-radius: 16px;
    }

    .icon{
        width: 5rem;
        height: 5rem;
    }

    @media (max-width: 1024px){
        .mobile{
            display: none;
        }

        .white-rectangle-img{
            width: 18rem;
            object-fit: cover;
        }

        .middle-rectangle{
            height: 18rem;
            width: 27rem;
        }

        .icon{
            width: 4rem;
            height: 4rem;
        }

        .centered-icon{
            top: -10px;
        }

        .storage-icon-container{
            padding: 1.3rem 1.8rem;
        }

        .plans-icon-container{
            padding: .8rem 1rem;
        }

        .small-icon{
            width: 2rem;
            height: 2rem;
        }

        .middle-title {
            margin-top: 0;
        }
    }

    @media (max-width: 768px){
        .desktop{
            display: none;
        }

        .mobile{
            display: block;
        }

        .join-title{
            font-size: 1.8rem;
        }

        .join-sub-title{
            font-size: 1.2rem;
        }

        .join-description{
            font-size: .9rem;
        }

        .join-text .contact-btn{
            font-size: 1rem;
            width: 15rem;
            padding: 1rem 0;
        }

        .mobile-rectangle-wrapper{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1.2rem;
        }

        .mobile-rectangle{
            object-fit: cover;
            width: 80%;
        }

        .mobile-centered-text:dir(rtl) {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.2rem;
        }

        .mobile-centered-text:dir(ltr) {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.2rem;
        }

        .mobile-centered-icon{
            position: absolute;
            right: 6rem;
            z-index: 1;
            width: 5rem;
            height: 5rem;
        }

        .mobile-storage-icon-container{
            position: absolute;
            padding: 1rem 1.5rem;
            z-index: 2;
            background-color: #165B83;
            right: 6rem;
            width: 2rem;
            height: 3rem;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

    @media (max-width: 460px){
        .desktop{
            display: none;
        }

        .join-title{
            font-size: 1.5rem;
        }

        .join-sub-title{
            font-size: 1.2rem;
        }

        .join-description{
            font-size: .9rem;
        }

        .join-description{
            max-width: 350px;
        }

        .mobile{
            display: block;
        }

        .middle-description{
            width: 100%;
        }

        .mobile-rectangle{
            object-fit: cover;
            width: 90%;
        }

        .mobile-centered-text:dir(rtl) {
            position: absolute;
            width: 250px;
            z-index: 1;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            margin-right: 2.2rem;
        }

        .mobile-centered-text:dir(ltr) {
            position: absolute;
            width: 250px;
            z-index: 1;
            top: 50%;
            left: 11rem;
            transform: translate(-50%, -50%);
            margin-right: 8rem;
        }

        .mobile-centered-icon{
            position: absolute;
            right: 1.5rem;
            width: 5rem;
            height: 4rem;
        }

        .mobile-storage-icon-container{
            position: absolute;
            padding: 1rem 1.5rem;
            z-index: 2;
            background-color: #165B83;
            right: 1.5rem;
            width: 1.3rem;
            height: 2rem;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .join-text{
            .contact-btn{
                padding: .8rem 0
            }
    }

    }
`