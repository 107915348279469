import styled from "styled-components";

export const AboutContainer = styled.section`
  margin-top: 14px;
  background-color: #0f3d57;

  .about-top {
    display: flex;
    justify-content: space-between;
    /* align-items: start; */
  }

  .circle-img {
    max-width: 100%;
  }

  .about-imgs {
    position: relative;
  }

  .about-text {
    color: white;
    padding: 50px;
    margin: 0 auto;
    z-index: 0;
  }

  .about-title {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 10rem;
  }

  .about-description {
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 700px;
    text-align: justify;
  }

  .circle-img:dir(ltr) {
    transform: rotateY(180deg);
    height: 600px;
    object-fit: cover;
  }

  .circle-img:dir(rtl) {
    height: 600px;
    object-fit: cover;
  }

  .car-img:dir(rtl) {
    position: absolute;
    right: -24rem;
    bottom: 45%;
    height: 240px;
  }

  .car-img:dir(ltr) {
    height: 240px;
  }

  .car-container:dir(ltr) {
    position: absolute;
    left: -24rem;
    bottom: 45%;
    transform: rotateY(180deg);
  }

  .green-bag:dir(rtl) {
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 16%;
  }

  .green-bag:dir(ltr) {
    width: 50%;
    position: absolute;
    left: 0;
    bottom: 17%;
    transform: rotateY(180deg);
  }

  .gray-bag:dir(rtl) {
    width: 50%;
    position: absolute;
    right: 70px;
    bottom: 15%;
  }

  .gray-bag:dir(ltr) {
    width: 50%;
    position: absolute;
    left: 70px;
    bottom: 15%;
    transform: rotateY(180deg);
  }

  .cards {
    display: flex;
    /* gap: 2.5rem; */
    justify-content: center;
  }

  .service-icon {
    height: 2.9rem;
    width: 2.9rem;
  }

  @media (max-width: 1024px) {
    .circle-img:dir(ltr) {
      height: 500px;
      object-fit: cover;
    }

    .circle-img:dir(rtl) {
      height: 500px;
      object-fit: cover;
    }

    .car-img:dir(ltr) {
      height: 200px;
    }

    .car-img:dir(rtl) {
      position: absolute;
      height: 200px;
      right: -20rem;
      bottom: 50%;
    }

    .car-container:dir(ltr) {
      position: absolute;
      left: -20rem;
      bottom: 50%;
    }

    .about-description {
      font-size: 1rem;
      font-weight: 400;
      max-width: 500px;
    }

    .about-title {
      font-size: 2.9rem;
      line-height: 5rem;
    }

    .green-bag:dir(rtl) {
      /* height: 160px; */
      width: 50%;
      bottom: 7.5rem;
    }

    .gray-bag:dir(rtl) {
      right: 80px;
      bottom: 7rem;
      /* height: 160px; */
      width: 50%;
    }

    .green-bag:dir(ltr) {
      /* height: 160px; */
      width: 50%;
      bottom: 7.5rem;
    }

    .gray-bag:dir(ltr) {
      left: 80px;
      bottom: 7rem;
      /* height: 160px; */
      width: 50%;
    }

    .cards {
      gap: 5rem;
    }

    .service-icon {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  @media (max-width: 768px) {
    .circle-img:dir(ltr) {
      height: 550px;
      object-fit: cover;
    }

    .circle-img:dir(rtl) {
      height: 550px;
      object-fit: cover;
    }

    .car-img:dir(ltr) {
      height: 200px;
    }

    .car-img:dir(rtl) {
      position: absolute;
      right: -16.7rem;
      bottom: 40%;
      height: 200px;
    }

    .car-container:dir(ltr) {
      position: absolute;
      left: -16.7rem;
      bottom: 40%;
    }

    .about-description {
      font-size: 0.9rem;
      font-weight: 400;
      max-width: 350px;
    }

    .about-title {
      font-size: 2.5rem;
      line-height: 5rem;
    }

    .green-bag:dir(rtl) {
      height: 130px;
      bottom: 6rem;
    }

    .gray-bag:dir(rtl) {
      right: 70px;
      bottom: 6rem;
      height: 130px;
    }

    .green-bag:dir(ltr) {
      height: 130px;
      bottom: 6rem;
    }

    .gray-bag:dir(ltr) {
      left: 70px;
      bottom: 6rem;
      height: 130px;
    }

    .cards {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2rem;
      margin-top: 2rem;
    }

    .service-icon {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  @media (max-width: 480px) {
    .about-top {
      flex-direction: column;
    }

    .about-text {
      margin-top: -50px;
    }
    .circle-img:dir(ltr) {
      /* height: 350px; */
      height: 40%;
      width: 50%;
      object-fit: cover;
    }
    .circle-img:dir(rtl) {
      height: 40%;
      width: 50%;
      object-fit: cover;
    }

    .car-img:dir(ltr) {
      height: 150px;
    }

    .car-img:dir(rtl) {
      position: absolute;
      right: -12.5rem;
      bottom: 50%;
      height: 150px;
    }

    .car-container:dir(ltr) {
      position: absolute;
      left: -12.5rem;
      bottom: 40%;
    }

    .about-description {
      /* font-size: .6rem;
            font-weight: 400;
            max-width: 300px; */
    }

    /* .about-text{
            color: white;
            padding: 5px;
            margin: 0 0;
            z-index: 0;
        } */

    .about-title {
      font-size: 2rem;
      line-height: 5rem;
    }

    .green-bag:dir(rtl) {
      /* height: 100px; */
      width: 30% !important;
      bottom: 3rem;
    }

    .gray-bag:dir(rtl) {
      right: 60px;
      bottom: 2.5rem;
      /* height: 100px; */
      width: 30% !important;
    }

    .green-bag:dir(ltr) {
      /* height: 100px !important; */
      width: 30% !important;
      bottom: 3rem;
      right: 70px;
    }

    .gray-bag:dir(ltr) {
      left: 60px;
      bottom: 3rem;
      /* height: 100px !important; */
      width: 30% !important;
    }

    .cards {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2rem;
      margin-top: 2rem;
    }

    .service-icon {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  /* margin-top: 60px;
    padding-bottom: 60px;
    background-color: #0F3D57;

    .car-img:dir(rtl){
        position: absolute;
        right: -30rem;
        bottom: 40%;
    }

    .car-container:dir(ltr){
        position: absolute;
        left: -30rem;
        bottom: 40%;
        transform: rotateY(180deg);
    }

    .green-bag:dir(rtl){
        position: absolute;
        right: 0;
        bottom: 16%;
    }

    .green-bag:dir(ltr){
        position: absolute;
        left: 0;
        bottom: 17%;
        transform: rotateY(180deg);
    }

    .gray-bag:dir(rtl){
        position: absolute;
        right: 100px;
        bottom: 15%;
    }

    .gray-bag:dir(ltr){
        position: absolute;
        left: 100px;
        bottom: 15%;
        transform: rotateY(180deg);
    }

    .about-text{
        color: white;
        max-width: 700px;
        padding: 50px;
        margin: auto;
    }

    .about-title{
        font-size: 5rem;
        font-weight: 700;
        line-height: 10rem;
    }

    .about-description{
        font-size: 1.2rem;
        font-weight: 400;
    }

    .cards{
        display: flex;
        padding: 0 5rem;
        justify-content: space-between;
    } */
`;
