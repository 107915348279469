import { useTranslation } from 'react-i18next'
import { CommonQuestionContainer } from './style'
import { ReactComponent as Line } from "../../assets/images/partner-line.svg"
import QuestionCard from '../common/Question/QuestionCard'
import { Questions } from '../../utils/data/questions'
import { QuestionsType } from '../../types/questions.interface'

const CommonQuestion = () => {
    const { t } = useTranslation();

    return (
        <CommonQuestionContainer>
            <div className='common-question-text'>
                <div className='common-question-title'>{t("commonQusetions")}</div>
                <Line />
            </div>
            <div className='common-question-card'>
                {/* <img className='question-marks' src="https://s3-alpha-sig.figma.com/img/4c7e/4944/41ee5afef93e8f1c914b86ef53a929a6?Expires=1731283200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pA5w8s8iJiQ4LSPUj6GYXm9AoYHxE3RE~bazjDNsFTa382lN2Yx82O796AT0qFg-Ty7s9ca7Q~XBdFUWA7sJyigGoPWc4BTrBf4UQ1rXK7G~VbEIjzFpse~qRtYgOkm2AdZGOxDTyTiuJJGXVZW0v9v5R123YUhs6-MvJMHxslgDnrMr3lh31E0s1PrNAq8Ftpm-2snyT5hxMGG6E9BW3IH-3fa8IMD1IZQeKmPX7OagPG4fRpJT1l-PeZC6QrqZnNuKO-AwovVkhVLHK7sY8WMiidi37wun10cz2YwKlhitQXT2YjROOENbDKhzUIG3JABrFRNJvWDxW4RXOGvxkg__" alt='question-mark' /> */}
                {Questions.map((question: QuestionsType, index: number) => (
                    <QuestionCard key={index} title={question.title} description={question.description} />
                ))}
            </div>
        </CommonQuestionContainer>
    )
}

export default CommonQuestion