import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import { AlertContainer } from "./style";

export const SuccessAlert: React.FC<{ message: string }> = ({ message }) => {
  return (
    <AlertContainer>
      <Alert icon={<CheckIcon fontSize="inherit" sx={{ pl: 2 }} />} severity="success" variant="filled">
        {message}
      </Alert>
    </AlertContainer>
  );
}
